<template>
  <div class="container d-flex flex-column align-center justify-center h-100 text-center">
    <img class="logo" :src="details.logo ? details.logo.url : ''" alt="Logo" />
    <div class="details d-flex flex-column align-center">
      <div class="name font-18 my-4">{{ details.name }}</div>
      <div class="address font-12" v-if="details.address">
        <a
          class="d-flex align-center text-capitalize"
          :href="`http://maps.google.com/?q=${details.address}`"
          target="_blank"
        >
          <img
            class="mr-3"
            :src="require('@/assets/images/icons/location.svg')"
            alt="Details"
          />
          {{ details.address }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 512px;
  height: 512px;
  border-radius: 50%;
  box-shadow: 0px 0px 9.56872px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: $breakpoint-md) {
    width: 300px;
    height: 300px;
  }
}

.name {
  font-size: 66px;
  font-weight: 900;

  @media (max-width: $breakpoint-md) {
    font-size: 33px;
  }
}

.address {
  width: fit-content;
  font-size: 20px;
  font-weight: 400;
  border-radius: 24px;
  background: #e9e9e9;
  padding: 5px 15px;

  img {
    height: 20px;
  }

  a {
    color: #000;
  }

  @media (max-width: $breakpoint-md) {
    font-size: 16px;

    img {
      height: 15px;
    }
  }
}
</style>
