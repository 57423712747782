<template>
  <div class="offer-details">
    <div
      class="offer-details__image-wrapper position-relative"
      :style="`background-image: url(${product.item.images[0].url || ''})`"
    >
      <button
        class="close d-flex justify-center align-center"
        @click="$emit('close')"
      >
        <img
          :src="require('@/assets/images/icons/offer-details-close-icon.svg')"
          alt="close"
        />
      </button>

      <!-- <img
        class="offer-details__image"
        :src="product.item.images[0].url || ''"
        alt="Product Image"
      /> -->
    </div>

    <div
      class="offer-card__content mt-5 px-3 pb-5 d-flex flex-column justify-between"
    >
      <!-- <p
        class="offer-card__banner font-12 font-700 w-fit-content ma-0"
        :title="product.item.banner.text"
        v-if="product.item.banner && product.item.banner.text"
      >
        {{ product.item.banner.text }}
      </p> -->

      <div class="group-name font-12 font-600">{{ product.groupName }}</div>

      <div class="d-flex align-center">
        <div class="offer-card__text pr-3">
          <div
            class="offer-card__title font-24 font-600"
            v-if="product.item.title"
            ref="titles"
          >
            <span>{{ product.item.title }}</span>
          </div>
          <div
            class="offer-card__description font-16 font-400"
            v-if="product.item.description"
            ref="descriptions"
            :title="product.item.description"
          >
            {{ product.item.description }}
          </div>
        </div>
        <div class="offer-card__prices pa-2">
          <span class="offer-card__price" v-if="product.item.price">
            <span class="font-26 font-900">{{ product.item.price }}</span
            ><span class="currency font-11" ref="currency">{{ currency }}</span>
          </span>
          <span class="discount" v-if="product.item.oldPrice"
            ><span class="font-20 font-400">{{ product.item.oldPrice }}</span
            ><span class="currency font-8" ref="discount_currency">{{
              currency
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    passedCurrency: {
      required: false,
      default: "",
    },
  },
  computed: {
    currency() {
      return this.profile.currency
        ? this.profile.currency.symbol
        : this.passedCurrency;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-details {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 22px;

  button.close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;

    position: absolute;
    top: 10px;
    right: 10px;
  }

  .offer-details__image-wrapper {
    height: 590px;
    border-radius: 14px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .offer-details__image {
      max-width: 590px;
      height: 590px;
    }
  }

  .group-name {
    width: fit-content;
    border-radius: 10.513px;
    background: #f4f4f4;
    padding: 8px 20px;
  }

  .offer-card__prices {
    width: fit-content;
    min-width: 85px;
    background: #fdd039;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: -2.16024px 0px 1.29615px -0.43205px rgba(0, 0, 0, 0.25);
    margin-left: auto;

    span {
      line-height: 1;
    }
  }
  .offer-card__price {
    font-weight: bold;
    font-size: 27px;
  }

  @media (max-width: 600px) {
    .offer-details__image-wrapper {
      height: 300px;
    }

    .offer-card__title {
      font-size: 20px;
    }

    .offer-card__price {
      font-size: 20px;
    }

    .discount {
      & > span:nth-of-type(1) {
        font-size: 15px;
      }
    }
  }
}
</style>
