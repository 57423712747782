<template>
  <div class="offers-page h-screen">
    <div v-if="isActive" class="offers-page-header">
      <div class="container d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <img
            class="logo"
            :src="details.logo ? details.logo.url : ''"
            alt="Logo"
          />
          <div class="details ml-7 d-flex flex-column">
            <div class="name font-18">{{ details.name }}</div>
            <div class="address font-12" v-if="details.address">
              <a
                class="d-flex align-center"
                :href="`http://maps.google.com/?q=${details.address}`"
                target="_blank"
              >
                <img
                  class="mr-2"
                  :src="require('@/assets/images/icons/location.svg')"
                  alt="Details"
                />
                {{ details.address }}</a
              >
            </div>
          </div>
        </div>
        <div>
          <button class="share-btn" @click="openShareDialog">
            <img
              class=""
              :src="require('@/assets/images/icons/share.svg')"
              alt="Details"
            />
          </button>
        </div>
      </div>
    </div>
    <div v-if="isActive" class="container">
      <v-row class="mx-auto py-6 offers-content">
        <div class="section mb-10" v-for="(offer, i) in offers" :key="i + 'A'">
          <div
            class="my-5"
            v-if="offer.products.some((prod) => prod.item.isOffer)"
          >
            <h2 class="group-name font-18">{{ offer.name }}</h2>
          </div>
          <v-row class="mt-4 card-parent flex-wrap align-start">
            <v-col
              v-for="product in offer.products"
              :key="product.item._id"
              lg="4"
              md="6"
              cols="12"
            >
              <offer-card
                @click.native="toggleOfferDetails(product, offer.name)"
                class="cursor-pointer"
                :product="product"
                :passed-currency="publicCurrency"
              />
            </v-col>
          </v-row>
        </div>
      </v-row>
    </div>

    <inactive-offers-page v-else :details="details" />

    <v-dialog
      content-class="offer-details-dialog"
      v-model="offerDetailsDialog"
      max-width="620px"
    >
      <offer-details
        :product="selectedOffer"
        :passed-currency="publicCurrency"
        @close="toggleOfferDetails()"
      />
    </v-dialog>

    <v-dialog v-model="shareDialog" content-class="white offers-dialog" max-width="450px">
      <share-offers-modal :no-preview="true" :no-qr-code="true" @close="shareDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import OfferCard from "../components/OfferCard.vue";
import InactiveOffersPage from "./components/InactiveOffersPage.vue";
import OfferDetails from "./components/OfferDetails.vue";
import ShareOffersModal from "../components/ShareOffersModal.vue";

export default {
  name: "OffersPage",
  components: {
    OfferCard,
    InactiveOffersPage,
    OfferDetails,
    ShareOffersModal,
  },
  data() {
    return {
      offers: [],
      details: {
        isOfferPageActive: true,
      },
      loading: false,
      publicCurrency: "",
      selectedOffer: {},
      offerDetailsDialog: false,
      shareDialog: false,
    };
  },
  mounted() {
    this.getOffers();
  },
  computed: {
    isActive() {
      return !!this.details.isOfferPageActive;
    },
  },
  methods: {
    getOffers() {
      this.loading = true;
      this.$axios
        .get(`${process.env.VUE_APP_OFFERS}/${this.$route.params.id}`)
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.offers = res.data.Categories;
            this.details = {
              logo: res.data.logo,
              name: res.data.username,
              address: res.data.location.address,
              isOfferPageActive: res.data.isOfferPageActive,
            };
            this.publicCurrency = res.data.currency.symbol;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          if (!err.isOfferPageActive) {
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong",
              color: "error",
            });

            return;
          }
        });
    },
    toggleOfferDetails(product = null, groupName) {
      console.log(product);
      this.selectedOffer = product ? { ...product, groupName } : {};
      this.offerDetailsDialog = product ? true : false;
    },
    openShareDialog() {
      this.shareDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.offers-page {
  .share-btn {
    border-radius: 12px;
    border: 1px solid #b0b0b0;
    background: #fff;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 25px;
    }
  }

  .offers-page-header {
    background: #fafafa;
    img.logo {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .address {
      border-radius: 24px;
      background: #e9e9e9;
      padding: 5px 15px;
      width: fit-content;

      img {
        height: 13px;
      }

      a {
        color: #000;
      }
    }
  }

  .offers-content {
    .section {
      width: 100%;

      .group-name {
        width: fit-content;
        border-radius: 10.513px;
        background: #f4f4f4;
        padding: 8px 20px;
      }
    }
  }
}
</style>

<style>
.offer-details-dialog {
  box-shadow: none;
}

.offers-dialog {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
